import React, {useEffect, useState, useCallback} from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as BrowserLink
} from "react-router-dom";
import '@shopify/polaris/dist/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import axios from "axios";
import CurrencyFormat from 'react-currency-format';
import { PieChart } from 'react-minimal-pie-chart';

import {
  AppProvider,
  Badge,
  Banner,
  ChoiceList,
  Form,
  Avatar,
  DataTable,
  Filters,
  Heading,
  Page,
  Layout,
  List,
  IndexTable,
  FormLayout,
  TextField,
  Card,
  Button,
  Navigation,
  Modal,
  Toast,
  Frame,
  Icon,
  TextContainer,
  TextStyle,
  ProgressBar,
  Link as ShopiLink,
  ResourceList,
  useIndexResourceState,
  ResourceItem,
  Spinner,
  Stack,
  Tag,
  Tooltip
} from '@shopify/polaris';
import {
  DetailedPopUpMajor
} from '@shopify/polaris-icons';
import {
  SenseiProvider,
  themeWhite,
  AreaChartCard,
  AreaChart2
} from "react-sensei";

const isSelected = (key) => {
  let url = window.location.href.toLowerCase()
  return url.includes(key.toLowerCase()) ? true : false
}
const money = (amount) => {
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)
}
const percentage = (amount)=>{
  return `${ (amount*100).toFixed(2)}%`
}
const findUniqProductTypes = (products) => {
  var types = products.map(p=>(p.productType))
  return  [...new Set(types)];
}
const earningsStats = [
  {
    label: "Revenue",
    value: "$34.5k",
  },
  {
    label: "Profit",
    value: "$2.3k",
  },
  {
    label: "Orders",
    value: "811",
  },
];
const areaSeries = [
  {
    data: [0, 35, 10, 44, 10, 0, 0],
  },
  {
    data: [0, 5, 35, 10, 64, 10, 0],
  },
  {
    data: [0, 20, 10, 30, 20, 28, 0],
  },
];
const App = () => {
  console.log("APP");
  const [user, setUser] = useState(localStorage.getItem('user'));
  useEffect(() => {

  }, []);

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            url: '/collections/home',
            label: 'Collections',
            selected: isSelected('/collections/'),
            subNavigationItems: [
              {
                label: 'Home',
                url: '/collections/dashboard/all',
                selected: isSelected('/collections/product/'),
              },
              {
                label: 'AW20',
                url: '/collections/dashboard/aw20',
                selected: isSelected('/collections/dashboard/aw20'),
              },
              {
                label: 'SS21',
                url: '/collections/dashboard/ss21',
                selected: isSelected('/collections/dashboard/ss21'),
              },
              {
                label: 'AW21',
                url: '/collections/dashboard/aw21',
                selected: isSelected('/collections/dashboard/aw21'),
              },
              {
                label: 'Permanent',
                url: '/collections/dashboard/permanent',
                selected: isSelected('/collections/dashboard/permanent'),
              },
              {
                label: 'Basics',
                url: '/collections/dashboard/basics',
                selected: isSelected('/collections/dashboard/basics'),
              },
              {
                label: 'Production AW20',
                url: '/collections/products/aw20',
                selected: isSelected('/collections/product/'),
              },

            ]
          },
          {
            url: '/semesters/2021-S2',
            label: 'Semestres',
            selected: isSelected('/semesters/'),
            subNavigationItems: [
              {
                label: '21-S1',
                url: '/semesters/2021-S1',
                selected: isSelected('/semesters/2021-S1/'),
              },
              {
                label: '21-S2',
                url: '/semesters/2021-S2',
                selected: isSelected('/semesters/2021-S2/'),
              }
            ]
          },
          {
            url: '/finances/dashboard',
            label: 'Rentability',
            selected: isSelected('/finances/dashboard'),
          }
        ]}
      />
      <Navigation.Section
        title="Eshop"
        items={[
          {
            label: 'Images',
            url: '/images',
            selected: isSelected('images'),
          }
        ]}
      />
      <Navigation.Section
        title="Retail"
        items={[
          {
            label: 'Returns in progress',
            url: '/retail/returns',
            selected: isSelected('Returns in progress'),
          }
        ]}
      />
    </Navigation>
  );
  if (user) {
    console.log("USER");
    return (
        <AppProvider i18n={enTranslations}>
          <Frame navigation={navigationMarkup}>
            <Router>
              <Switch>

                <Route path="/performance/products"         component={PerformanceProducts} />
                <Route path="/performance/types"            component={PerformanceTypes} />
                <Route path="/performance/returns"          component={ReturnPerformancePage} />
                <Route path="/performance/sales"            component={SalePerformance} />
                <Route path="/performance/gender"           component={GenderPerformance} />




                <Route path="/collections/dashboard/all"          component={CollectionsHome} />
                <Route path="/collections/dashboard/ss21"         component={prop=><CollectionDashboard seasonCode="ss21"/>} />
                <Route path="/collections/dashboard/aw21"         component={prop=><CollectionDashboard seasonCode="aw21"/>} />
                <Route path="/collections/dashboard/aw20"         component={prop=><CollectionDashboard seasonCode="aw20"/>} />
                <Route path="/collections/dashboard/permanent"    component={prop=><CollectionDashboard seasonCode="permanent"/>} />
                <Route path="/collections/dashboard/basics"       component={prop=><CollectionDashboard seasonCode="basics"/>} />


                <Route path="/collections/products/aw20"    component={CollectionProducts} />



                <Route path="/semesters/2021-S2"   component={prop=><SemesterDashboard year="2021" semester="S2"/>} />
                <Route path="/semesters/2021-S1"   component={prop=><SemesterDashboard year="2021" semester="S1"/>} />




                <Route path="/finances/dashboard"     component={FinancesDashboard} />

                <Route path="/images" component={ImagesPage} />

                <Route path="/retail/returns" component={PageRetailReturns} />

              </Switch>
            </Router>
          </Frame>
        </AppProvider>
    );
  }
  return(
    <Signin></Signin>
  )

}

const SalePerformance = () => {
  const [orders, setOrders] = useState([])

  useEffect( async () => {
    const response = await axios.get("/api/orders");
    setOrders(response.data)
    console.log(response.data);
  }, []);
  const fetchData = () => {
    console.log("fetchData");
    axios.put("/api/orders");
  }

  return(
    <SenseiProvider theme={themeWhite} >
      <div id="font-script"></div>
      <div id="icon-script"></div>
      <Page primaryAction={{
          content: 'Refresh',
          onAction: fetchData
        }}>
        <Card title="Awesome" sectioned>
          <Card.Section>
            <AreaChartCard
                         cardTitle="Earnings"
                         statList={earningsStats}
                         series={areaSeries}
                       />
          </Card.Section>
          <Card.Section>
            {}
          </Card.Section>

        </Card>
      </Page>


    </SenseiProvider>
  )
}


const GenderPerformance = () => {
  const [genders, setGenders] = useState([])
  useEffect( async () => {
    const response = await axios.get("/api/genders");
    setGenders(response.data.genders)
    console.log(response.data.genders);
  }, []);

  const data2 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    series: [[0, 4, 2, 7, 3, 12, 2, 4, 0]],
  };
  return(
    <SenseiProvider theme={themeWhite} >
      <div id="font-script"></div>
      <div id="icon-script"></div>
      <div id="preloader"></div>
      <Page title="Gender performance"
        subtitle="Basé sur les ventes depuis le 1er janvier 2021">
        {genders.length?
          genders.map(gender=>{
            const {statList, title, types, numberOfItems} = gender
            var rows = types.map(type=>{
              const {quantity} = type
              const percentageOfSale = quantity/numberOfItems * 100
              return [
                type.title,
                type.quantity,
                <Tooltip content={`Représente ${percentageOfSale.toFixed(2)}% des articles vendus sur ce gender`}>
                  <ProgressBar progress={ percentageOfSale } color="highlight"/>
                </Tooltip>,
              ]
            })
            rows.length = 4
            return (
              <Card title={title}>
                 <Card.Section>
                   <AreaChartCard
                      statList={statList}
                      series={areaSeries}
                    />
                 </Card.Section>
                 <Card.Section>
                   <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'numeric'
                    ]}
                    headings={[
                      'Type',
                      'Sold',
                      'Sale repartition'
                    ]}
                    rows={rows}
                  />
                 </Card.Section>
              </Card>
            )
          })
          :
          <Spinner></Spinner>
        }
      </Page>
    </SenseiProvider>
  )
}
const PageRetailReturns = () => {
  const [returnsInProgress, setReturnsInProgress] = useState([])
  useEffect( async () => {
    const response = await axios.post("/api/retail/returns");
    var {returns, trackings} = response.data;
    returns = returns.map((oneReturn, i)=>{
      return {
        ...oneReturn,
        tracking : trackings[i]
      }
    })
    setReturnsInProgress(returns);
    console.log(returns);
  }, []);

  const resourceName = {
    singular: 'return',
    plural: 'returns',
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(returnsInProgress);
  const rowMarkup = returnsInProgress.map(
    ({
      name,
      createdAt,
      parcelNumber,
      email,
      items,
      tracking
    }, index) => (
      <IndexTable.Row
        id={`id--${index}`}
        key={`index--${index}`}
        selected={selectedResources.includes(`id--${index}`)}
        position={index}
      >
        <IndexTable.Cell>{name}</IndexTable.Cell>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>
          <ParcelModal parcelNumber={parcelNumber} tracking={tracking}/>
        </IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
        <IndexTable.Cell>
          <ReturnModal items={items} name={name}/>
        </IndexTable.Cell>

      </IndexTable.Row>
    ),
  );
  return(
    <Page title="Returns"
      subtitle="Follow up with returns currently in transit."

        >
      <Card title="In Progress" sectioned>
        {returnsInProgress.length?
          <div>
            <IndexTable
                resourceName={resourceName}
                itemCount={returnsInProgress.length}
                selectedItemsCount={
                  allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  {title: 'Order'},
                  {title: 'Date'},
                  {title: 'Parcel'},
                  {title: 'Email'},
                  {title: 'Products'}
                ]}
                >
                {rowMarkup}
            </IndexTable>
          </div>
          :
          <Spinner></Spinner>

        }
      </Card>
    </Page>
  )
}
const PerformanceProducts = () => {
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(null);
  const [sortedRows, setSortedRows] = useState(null);

  useEffect( async () => {
    const response = await axios.post("/api/dashboard");
    const {products, totals} = response.data;
    setProducts(products);
    setTotals(totals);
  }, []);

  const productRows = products.map( product => {
    const {
      title,
      productType,
      inventorySold,
      inventoryReceived,
      lineMargin,
      scoreInCategory,
    } = product;
    let percentCA = 0;
    if (totals) {
      percentCA = (lineMargin / totals.margin) * 100
    }

    const productRows = product.variants.map(variant=>{
      const {
        title,
        inventoryReceived,
        inventorySold,
        saleThrough
      } = variant
      const scoreInCategory = parseFloat((inventorySold / product.inventorySold) * 100).toFixed(2)
      return [
        title,
        inventoryReceived,
        inventorySold,
        <Tooltip content={`${scoreInCategory}% des tailles vendues`}>
          <ProgressBar progress={ scoreInCategory } color="highlight"/>
        </Tooltip>,
        <Tooltip content={`${inventorySold}/${inventoryReceived}`}>
          <ProgressBar progress={saleThrough * 100} color="success"/>
        </Tooltip>,
      ]
    })
    const modalContent = <DataTable
      columnContentTypes={[
        'text',
        'numeric',
        'text',
        'text',
        'text',
      ]}
      headings={[
        'Taille',
        'Received',
        'inventorySold',
        'Sales repartition',
        'Sale through',
      ]}
      rows={productRows}
      />
    return [
      title,
      productType,
      inventoryReceived,
      inventorySold,
      <Tooltip content={`${scoreInCategory}% des articles vendus dans cette categorie`}>
        <ProgressBar progress={ scoreInCategory } color="highlight"/>
      </Tooltip>,
      <Tooltip content={`${inventorySold}/${inventoryReceived}`}>
        <ProgressBar progress={product.saleThrough * 100} color="success"/>
      </Tooltip>,
      <ModalNew title={title} content={modalContent}/>
    ]
  })
  const rows = sortedRows ? sortedRows : productRows;
  const handleSort = useCallback(
      (index, direction) => setSortedRows(rows),
      [rows],
    );

  return(
    <Page title="SS21 Product performance"
      subtitle="Based on transfers and inventories"
      fullWidth>
      <Layout>
        <Layout.Section>
          <Card title="By product" sectioned>
            {products.length?
              <DataTable
                columnContentTypes={[
                  'text',
                  'text',
                  'numeric',
                  'numeric',
                  'text',
                  'text',
                  'text'
                ]}
                headings={[
                  'Product',
                  'Type',
                  'Received',
                  'inventorySold',
                  '% des ventes',
                  'Sale through',
                  'Details'
                ]}
                rows={productRows}
                onSort={handleSort}
                sortable={[false, false, false, false, false]}

                />
              :
              <Spinner accessibilityLabel="Spinner example" size="large" />
            }
          </Card>
        </Layout.Section>


      </Layout>

    </Page>
  )
}
const PerformanceTypes = () => {
  console.log("PerformanceTypes");
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(null);
  const [types, setTypes] = useState([]);
  const [returns, setReturns] = useState([]);

  useEffect( async () => {
    const response = await axios.post("/api/dashboard");
    console.log(response.data);
    const {products, types, totals, allReturns} = response.data;
    setProducts(products);
    setTotals(totals);
    setTypes(types);
    setReturns(returns);
  }, []);


  const typeRows = types.map( type => {
    const {
      inventorySold,
      inventoryReceived,
      models,
      title,
      inventoryValue
    } = type
    let percentCA = 1;
    let inventorySoldPercentage = 0;
    if (totals) {
      percentCA = (type.lineMargin / totals.margin) * 100
      inventorySoldPercentage = ( inventorySold / totals.inventorySold) * 100;
    }

    const inventorySoldInType = inventorySold
    const inventoryReceivedType = type.inventoryReceived
    const sizeRows = type.sizes.map( (size) => {
      const {inventorySold, inventoryReceived, title} = size
      const inventorySoldPercentage = (inventorySold / inventorySoldInType) * 100
      return [
        title,
        inventoryReceived,
        inventorySold,
        <Tooltip content={`${inventorySold}/${inventorySoldInType}, soit ${parseInt(inventorySoldPercentage)}% des articles vendus`}>
          <ProgressBar progress={inventorySoldPercentage} color="highlight" />
        </Tooltip>,
        <Tooltip content={`${inventorySold}/${inventoryReceived}`}>
          <ProgressBar progress={inventorySold/inventoryReceived*100} color="success" />
        </Tooltip>,
      ]
    });
    const modalContent = <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'text',
                'text',
                'text',
              ]}
              headings={[
                'Taille',
                'Received',
                'Sold',
                'Sales repartition',
                'Sale through',

              ]}
              rows={sizeRows}
              />

    return [
      title,
      models,
      inventoryReceived,
      inventorySold,
      <Tooltip content={`${parseInt(inventorySoldPercentage)}% des articles vendus`}>
        <ProgressBar progress={ inventorySoldPercentage} color="highlight"/>
      </Tooltip>,
      <Tooltip content={`${inventorySold}/${inventoryReceived}`}>
        <ProgressBar progress={type.saleThrough * 100} color="success" />
      </Tooltip>,
      money(inventoryValue),
      <ModalNew title={title} content={modalContent}/>
    ]
  });


  return(
    <Page title="Dashboard SS21"
      subtitle="Based on transfers and inventories"
      fullWidth>
      <Layout>
        <Layout.Section>
          <Card title="By type" sectioned>
            {types.length?
              <DataTable
                columnContentTypes={[
                  'text',
                  'numeric',
                  'numeric',
                  'numeric',
                  'text',
                  'text',
                  'numeric',
                  'text'
                ]}
                headings={[
                  'Type',
                  'Models',
                  'Received',
                  'Sold',
                  'Quantity sold',
                  'Sale through',
                  'inventoryValue',
                  'Details'
                ]}
                rows={typeRows}
                totals={[
                  '',
                  '',
                  '',
                  0,
                  0,
                  0,
                  money(types.reduce((total, type)=>(total+=type.inventoryValue), 0)),
                  '',
                ]}
                showTotalsInFooter
                />
              :
              <Spinner accessibilityLabel="Spinner example" size="large" />
            }
          </Card>
        </Layout.Section>

      </Layout>

    </Page>
  )
}


const producedValue = (products) => {
  return products.reduce((total, product)=>{
    return total+=product.producedValue
  }, 0)
}
const producedQuantity = (products) => {
  return products.reduce((total, product)=>{
    return total+=product.producedQuantity
  }, 0)
}
const producedRetailPrice = (products) => {
  return products.reduce((total, product)=>{
    return total+=product.producedRetailPrice
  }, 0)
}

const instockRetailValue = (products)=>{
  return products.reduce((total, product)=>{
    return total+= product.variants.edges.reduce((sub, edge)=>{
      return sub += parseFloat(edge.node.compareAtPrice) * edge.node.inventoryQuantity
    }, 0)
  }, 0)
}
const CollectionsHome = () => {

  const [ss21, setSs21] = useState([]);
  const [aw21, setAw21] = useState([]);
  const [aw20, setAw20] = useState([]);
  const [permanents, setPermanents] = useState([]);
  const [basics, setBasics] = useState([]);

  const inventoryValue = (products) => {
    const value = products.reduce((total, product)=>{
      return total+=product.inventoryValue
    }, 0)
    return money(value)
  }
  const inventoryQuantity = (products) => {
    return products.reduce((total, product)=>{
      return total+=product.inventoryQuantity
    }, 0)
  }
  useEffect( async () => {
    var response = await axios.post("/api/products?tag=ss21");
    setSs21(response.data.products)
    console.log(response.data.products);

    response = await axios.post("/api/products?tag=permanent");
    console.log(response.data.products);
    setPermanents(response.data.products)


    response = await axios.post("/api/products?tag=basics");
    console.log(response.data.products);
    setBasics(response.data.products)

    console.log(inventoryQuantity(basics));


    response = await axios.post("/api/products?tag=aw21");
    console.log(response.data.products);
    setAw21(response.data.products);

    response = await axios.post("/api/products?tag=aw20");
    console.log(response.data.products);
    setAw20(response.data.products);

  }, [])
  return(
    <Page title="Home" subtitle="Overview des principales caractéristiques des collections" fullWidth>
      <Layout>
        <Layout.Section oneHalf>
          <Card title="INVENTORY LEVELS (ht)">
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
              ]}
              headings={[
                'Collection',
                'Inventory retail price',
                'Inventory retail compare at price',
              ]}
              rows={[
                [
                  <ShopiLink url={`/collections/dashboard/AW20`}>AW20</ShopiLink>,
                  money(aw20.reduce((t,p)=>(t+= p.inventoryRetailValueHt),0)),
                  money(aw20.reduce((t,p)=>(t+= p.inventoryRetailValueHtCompare),0)),
                ],
                [
                  <ShopiLink url={`/collections/dashboard/SS21`}>SS21</ShopiLink>,
                  money(ss21.reduce((t,p)=>(t+= p.inventoryRetailValueHt),0)),
                  money(ss21.reduce((t,p)=>(t+= p.inventoryRetailValueHtCompare),0)),
                ],
                [
                  <ShopiLink url={`/collections/dashboard/AW21`}>AW21</ShopiLink>,
                  money(aw21.reduce((t,p)=>(t+= p.inventoryRetailValueHt),0)),
                  money(aw21.reduce((t,p)=>(t+= p.inventoryRetailValueHtCompare),0)),
                ],
                [
                  <ShopiLink url={`/collections/dashboard/Permanent`}>Permanent</ShopiLink>,
                  money(permanents.reduce((t,p)=>(t+= p.inventoryRetailValueHt),0)),
                  money(permanents.reduce((t,p)=>(t+= p.inventoryRetailValueHtCompare),0)),
                ],
                [
                  <ShopiLink url={`/collections/dashboard/Basics`}>Basics</ShopiLink>,
                  money(basics.reduce((t,p)=>(t+= p.inventoryRetailValueHt),0)),
                  money(basics.reduce((t,p)=>(t+= p.inventoryRetailValueHtCompare),0)),
                ]
              ]}
            />
          </Card>
          <Card title="SALE THROUGH">
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'text',
              ]}
              headings={[
                'Collection',
                'Produced',
                'Unit sold',
                'Sale Through',
              ]}
              rows={[
                [
                  <ShopiLink url={`/collections/dashboard/AW20`}>AW20</ShopiLink>,
                  aw20.reduce((t,p)=>(t+=p.producedQuantity),0),
                  aw20.reduce((t,p)=>(t+=p.soldQuantity),0),
                  <ProgressBar progress={ aw20.reduce((t,p)=>(t+=p.soldQuantity),0) * 100 / aw20.reduce((t,p)=>(t+=p.producedQuantity),0) } />
                ],
                [
                  <ShopiLink url={`/collections/dashboard/SS21`}>SS21</ShopiLink>,
                  ss21.reduce((t,p)=>(t+=p.producedQuantity),0),
                  ss21.reduce((t,p)=>(t+=p.soldQuantity),0),
                  <ProgressBar progress={  ss21.reduce((t,p)=>(t+=p.soldQuantity),0) / ss21.reduce((t,p)=>(t+=p.producedQuantity),0) * 100} /> ],
                [
                  <ShopiLink url={`/collections/dashboard/AW21`}>AW21</ShopiLink>,
                  aw21.reduce((t,p)=>(t+=p.producedQuantity),0),
                  aw21.reduce((t,p)=>(t+=p.soldQuantity),0),
                  <ProgressBar progress={ aw21.reduce((t,p)=>(t+=p.soldQuantity),0) / aw21.reduce((t,p)=>(t+=p.producedQuantity),0) * 100} /> ],
                [
                  <ShopiLink url={`/collections/dashboard/Permanent`}>Permanent</ShopiLink>,
                  permanents.reduce((t,p)=>(t+=p.producedQuantity),0),
                  permanents.reduce((t,p)=>(t+=p.soldQuantity),0),
                  <ProgressBar progress={ permanents.reduce((t,p)=>(t+=p.soldQuantity),0) / permanents.reduce((t,p)=>(t+=p.producedQuantity),0) * 100} /> ],
                [
                  <ShopiLink url={`/collections/dashboard/Basics`}>Basics</ShopiLink>,
                  basics.reduce((t,p)=>(t+=p.producedQuantity),0),
                  basics.reduce((t,p)=>(t+=p.soldQuantity),0),
                  <ProgressBar progress={ basics.reduce((t,p)=>(t+=p.soldQuantity),0) / basics.reduce((t,p)=>(t+=p.producedQuantity),0) * 100} /> ],
              ]}
            />
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card title="PLAN DE COLLECTION">
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
              ]}
              headings={[
                'Collection',
                'Products',
                'Produced',
                'Budget Prod (ht)',
                'Max turnover (ht)',
              ]}
              rows={[
                [
                  <ShopiLink url={`/collections/dashboard/aw20`}>AW20</ShopiLink>,
                  aw20.length,
                  aw20.reduce((total, product)=>{
                    return total+=product.producedQuantity
                  }, 0),
                  money(producedValue(aw20)),
                  money(aw20.reduce((t,p)=>(t+=p.producedRetailPrice),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/ss21`}>SS21</ShopiLink>,
                  ss21.length,
                  ss21.reduce((total, product)=>{
                    return total+=product.producedQuantity
                  }, 0),
                  money(producedValue(ss21)),
                  money(ss21.reduce((t,p)=>(t+=p.producedRetailPrice),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/aw21`}>AW21</ShopiLink>,
                  aw21.length,
                  aw21.reduce((total, product)=>{
                    return total+=product.producedQuantity
                  }, 0),
                  money(producedValue(aw21)),
                  money(aw21.reduce((t,p)=>(t+=p.producedRetailPrice),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/permanent`}>Permanent</ShopiLink>,
                  permanents.length,
                  permanents.reduce((total, product)=>{
                    return total+=product.producedQuantity
                  }, 0),
                  money(producedValue(permanents)),
                  money(permanents.reduce((t,p)=>(t+=p.producedRetailPrice),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/Basics`}>Basics</ShopiLink>,
                  basics.length,
                  basics.reduce((total, product)=>{
                    return total+=product.producedQuantity
                  }, 0),
                  money(producedValue(basics)),
                  money(basics.reduce((t,p)=>(t+=p.producedRetailPrice),0))
                ],
              ]}
            />
          </Card>
          <Card title="RESULTAT FINANCIER">
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
              ]}
              headings={[
                'Collection',
                'Budget Prod',
                'Ventes (ht)',
                'Result'
              ]}
              rows={[
                [
                  <ShopiLink url={`/collections/dashboard/aw20`}>AW20</ShopiLink>,
                  money(aw20.reduce((t,p)=>(t+=p.producedValue),0)),
                  money(aw20.reduce((t,p)=>(t+=p.turnover_ht),0)),
                  money(aw20.reduce((t,p)=>(t+=p.turnover_ht),0) - aw20.reduce((t,p)=>(t+=p.producedValue),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/ss21`}>SS21</ShopiLink>,
                  money(ss21.reduce((t,p)=>(t+=p.producedValue),0)),
                  money(ss21.reduce((t,p)=>(t+=p.turnover_ht),0)),
                  money(ss21.reduce((t,p)=>(t+=p.turnover_ht),0) - ss21.reduce((t,p)=>(t+=p.producedValue),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/aw21`}>AW21</ShopiLink>,
                  money(aw21.reduce((t,p)=>(t+=p.producedValue),0)),
                  money(aw21.reduce((t,p)=>(t+=p.turnover_ht),0)),
                  money(aw21.reduce((t,p)=>(t+=p.turnover_ht),0) - aw21.reduce((t,p)=>(t+=p.producedValue),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/permanent`}>Permanent</ShopiLink>,
                  money(permanents.reduce((t,p)=>(t+=p.producedValue),0)),
                  money(permanents.reduce((t,p)=>(t+=p.turnover_ht),0)),
                  money(permanents.reduce((t,p)=>(t+=p.turnover_ht),0) - permanents.reduce((t,p)=>(t+=p.producedValue),0))
                ],
                [
                  <ShopiLink url={`/collections/dashboard/basics`}>Basics</ShopiLink>,
                  money(basics.reduce((t,p)=>(t+=p.producedValue),0)),
                  money(basics.reduce((t,p)=>(t+=p.turnover_ht),0)),
                  money(basics.reduce((t,p)=>(t+=p.turnover_ht),0) - basics.reduce((t,p)=>(t+=p.producedValue),0))
                ],
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}
const FinancesDashboard = () => {

  const [incomeRows, setIncomeRows] = useState([])
  const [incomeTotals, setIncomeTotals] = useState([])
  const [expenseRows, setExpenseRows] = useState([])
  const [expenseTotals, setExpenseTotals] = useState([])
  const [results, setResults] = useState([])
  const [stockValue, setStockValue] = useState(null)
  const [graphData, setGraphData] = useState([])

  useEffect( async () => {
    const response = await axios.post("/api/finances/dashboard");

    var {
      incomeRows,
      incomeTotals,
      expenseRows,
      expenseTotals,
      stockValue
    } = response.data;

    console.log(response.data);

    var datas     = incomeTotals.map( (v,i) => {
      return v/1000
    });
    console.log("datas", datas);
    datas.shift();
    datas.pop();
    console.log("datas", datas);
    console.log(datas.length);
    setGraphData(datas)

    setResults(incomeTotals.map((v,i)=>{
      return money(incomeTotals[i] - expenseTotals[i])
    }))

    incomeRows    = incomeRows.map(   v => {
      return v.map(x=>(isNaN(x) ? x : money(x)))
    } );
    incomeTotals  = incomeTotals.map( v=>( isNaN(v) ? v : money(v) ));
    expenseRows   = expenseRows.map(   v => {
      return v.map(x=>(isNaN(x) ? x : money(x)))
    } );
    expenseTotals = expenseTotals.map(v=>( isNaN(v) ? v : money(v) ));

    setIncomeRows(incomeRows)
    setIncomeTotals(incomeTotals)
    setExpenseRows(expenseRows)
    setExpenseTotals(expenseTotals)
    setStockValue(stockValue)



  }, []);


  const data = {
    labels: ["Mars", "Avril", "Mai", "Juin", "Juillet", "Aout" ],
    series: [
      graphData,
    ],
  };

  const columnContentTypes = [
    'text',
    'numeric',
    'numeric',
    'numeric',
    'numeric',
    'numeric',
    'numeric',
    'numeric',
  ]
  return (
    <Page
        title="Rentability"
        fullWidth
        >
      <Card sectioned>
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={[
            'MARGIN',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Aout',
            'Total'
          ]}
          rows={incomeRows}
          showTotalsInFooter
          totals={incomeTotals}
        />
      </Card>
      <Card sectioned>
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={[
            'SPENT',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Aout',
            'Total'
          ]}
          rows={expenseRows}
          showTotalsInFooter
          totals={expenseTotals}
        />
      </Card>
      <Card sectioned>

        <Banner
          title="Etat du stock"
          status="warning"
        >
          <p>
            Merci de noter qu'il y a en ce moment {money(stockValue)} de stock en valeur d'achat sur les produits Shopify qui ont le tag SS21.
          </p>
        </Banner>

        <DataTable
          columnContentTypes={columnContentTypes}
          headings={[
            'RESULTAT',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Aout',
            'Total'
          ]}
          rows={[
            incomeTotals,
            expenseTotals,
          ]}
          showTotalsInFooter
          totals={results}
        />

      </Card>
    </Page>
  )
}
const SemesterDashboard = (props) => {
  const {semester, year} = props;

  useEffect( async () => {
    // const response = await axios.post("/api/finances/dashboard");
  }, []);

  return (
    <Page
        title={`Semester Dashboard ${year} - ${semester}`}
        fullWidth
        >
      <Card sectioned title="Targets">

      </Card>

    </Page>
  )
}


const CollectionDashboard = (props) => {
  const {seasonCode} = props
  const [products, setProducts] = useState([])
  const [draftOrders, setDraftOrders] = useState([])
  const [targets, setTargets] = useState(null)
  useEffect( async () => {
    var response = await axios.post(`/api/products?tag=${seasonCode}`);
    setProducts(response.data.products);
    console.log(response.data.products);

    response = await axios.post(`/api/draftOrders?tag=${seasonCode}`);
    setDraftOrders(response.data.draftOrders);
    console.log(response.data.draftOrders);

    response = await axios.post(`/api/targets?seasonCode=${seasonCode}`);
    console.log(response.data);
    setTargets(response.data);


  }, []);

  const types = findUniqProductTypes(products);

  const inventoryValue = products.reduce((total, product)=>{
    return total+=product.inventoryValue
  }, 0)
  const wholesaleOrdersValue = draftOrders.reduce((total, order)=>{
    return total += order.lineItems.edges.reduce((subtotal, lineItem)=>{
      return subtotal += parseFloat(lineItem.node.variant.inventoryItem.unitCost.amount) * lineItem.node.quantity
    }, 0)
  }, 0)
  const buildTypeRows = (products) => {
    if (products.length) {
      var types = products.map(p=>(p.productType))
      types =  [...new Set(types)];
      types = types.map(type=>{
        var name = type;
        let same_products = products.filter((p)=>(p.productType == name ? true : false ))
        let producedRetailPrice = same_products.reduce((t,p)=>(t+=p.producedRetailPrice),0)
        let producedValue = same_products.reduce((t,p)=>(t+=p.producedValue),0)


        return [
          name,
          same_products.length,
          same_products.reduce((t,p)=>(t+=p.producedQuantity), 0),
          money(same_products.reduce((t,p)=>(t+=p.producedValue),0)),
          percentage(1-(producedValue/producedRetailPrice)),
          money(same_products.reduce((t,p)=>(t+=p.producedRetailPrice), 0)),
        ]
      })
      return types
    }
    return []
  }

  const [sortedRows, setSortedRows] = useState(null);
  const initiallySortedRows = buildTypeRows(products);
  const rows = sortedRows ? sortedRows : initiallySortedRows;
  const handleSort = useCallback((index, direction) => {
    var sortedRows = rows.sort( (a,b) => {
      console.log("Sort on index", index, direction);
      if (direction === "descending") {
        return a[index] - b[index]
      }
      return b[index] - a[index];
    })
    setSortedRows(sortedRows)
  },[rows]);


  var total_margin = products.reduce((t,p)=>(t+=p.total_margin),0)
  var turnover_ht = products.reduce((t,p)=>(t+=p.turnover_ht),0)

  return (
    <Page title={`Dashboard ${seasonCode.toUpperCase()}`}
            subtitle={`Cette page présente le plan de collection ${seasonCode.toUpperCase()} et le potentiels des familles de produit et des produits eux même`}
            fullWidth>
      <Layout>
        <Layout.Section>
          {targets?
            <Card title="Targets (HT)" >
            <Card.Section>
              <TextContainer>
                Rappel à titre informatif des chiffres d'affaire HT visés par channel.
              </TextContainer>
            </Card.Section>
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
              ]}
              headings={[
                'Location',
                'Sept',
                'Oct',
                'Nov',
                'Dec',
                'Jan',
                'Feb',
                'Total'
              ]}
              rows={[
                ["Commines", ...targets.commines.map(v=>money(v))],
                ["Printemps", ...targets.printemps.map(v=>money(v))],
                ["Eshop", ...targets.eshop.map(v=>money(v))],
                ["Wedressfair", ...targets.wedressfair.map(v=>money(v))]
              ]}
              showTotalsInFooter
              totals={['Total', ...targets.totals.map(v=>money(v))]}
            />
          </Card>
            :
            <span></span>
          }

          <Card title="Plan de collection">
            {products.length?
              <DataTable
                columnContentTypes={[
                  'text',
                  'numeric',
                  'numeric',
                  'numeric',
                  'numeric',
                  'numeric',
                ]}
                headings={[
                  'Type',
                  'Products',
                  'Produced',
                  'Budget prod',
                  'Margin',
                  'RetailValue HT',
                ]}
                rows={rows}
                sortable={[false, true, true, false, false]}
                showTotalsInFooter
                totals={
                  [
                    0,
                    products.length,
                    products.reduce((t,p)=>(t+=p.producedQuantity),0),
                    money(products.reduce((t,p)=>(t+=p.producedValue),0)),
                    percentage(1 - ( producedValue(products) / producedRetailPrice(products) )),
                    money(products.reduce((t,p)=>(t+=p.producedRetailPrice),0)),
                  ]
                }
                onSort={handleSort}
              />
              :
              <Spinner></Spinner>
            }
          </Card>

          <Card title="Performance de la collection">
            <Card.Section sectioned>
              <TextContainer>
                <p>
                  Basées sur les ventes depuis le 1er janvier
                </p>
              </TextContainer>
            </Card.Section>


            {products.length?
              <DataTable
                columnContentTypes={[
                  'text',
                  'numeric',
                  'numeric',
                  'numeric',
                  'numeric',
                  'numeric',
                  'text',
                ]}
                headings={[
                  'Type',
                  'Units sold',
                  'COGS',
                  'Cogs %',
                  'Marge réelle',
                  'Marge sur vente',
                  'Inventory Cost',
                  'Sale through',
                ]}
                rows={types.map(type=>{
                  var soldQuantity = products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.soldQuantity),0)
                  var producedQuantity = products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.producedQuantity),0)
                  var total_margin = products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.total_margin),0)
                  var turnover_ht = products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.turnover_ht),0)

                  return [
                    type,
                    <TailleModal type={type} soldQuantity={soldQuantity} products={products.filter(p=>(p.productType == type ? true : false))}/>,
                    money(products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.soldQuantity*parseFloat(p.variants.edges[0].node.inventoryItem.unitCost.amount)),0)),
                    percentage(
                      products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.soldQuantity*parseFloat(p.variants.edges[0].node.inventoryItem.unitCost.amount)),0)
                      /
                      products.reduce((t,p)=>(t+=p.soldQuantity*parseFloat(p.variants.edges[0].node.inventoryItem.unitCost.amount)),0)

                    ),
                    percentage(total_margin/turnover_ht),
                    money(products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.total_margin),0)),
                    money(products.filter(p=>(p.productType == type ? true : false)).reduce((t,p)=>(t+=p.inventoryValue),0)),
                    <ProgressBar progress={ soldQuantity / producedQuantity * 100 } />
                  ]
                })}
                sortable={[false, true, true, false, false]}
                onSort={handleSort}
                showTotalsInFooter
                totals={[
                  0,
                  products.reduce((t,p)=>(t+=p.soldQuantity),0),
                  money(products.reduce((t,p)=>(t+=p.soldQuantity*parseFloat(p.variants.edges[0].node.inventoryItem.unitCost.amount)),0)),
                  '100%',
                  percentage(total_margin/turnover_ht),
                  money(products.reduce((t,p)=>(t+=p.total_margin),0)),
                  money(products.reduce((t,p)=>(t+=p.inventoryValue),0)),
                  '-',
                ]}
              />
              :
              <Spinner></Spinner>
            }
          </Card>

          {products.length?
            <CollectionProductTable products={products}/>
            :
            <Spinner></Spinner>
          }

        </Layout.Section>

      </Layout>




    </Page>
  )
}
const CollectionProducts = () => {
  const [products, setProducts] = useState([])
  useEffect( async () => {
    const response = await axios.post("/api/products?tag=aw20");
    var {products} = response.data;
    setProducts(products);
    console.log(products);
  }, []);

  return(
    <Page title="Collection products">
      {products.length?
        products.map( (product, i)=>{
          return (
            <Card title={product.title} key={i}>
              <Card.Section title="TOTAL">
                En tout on voit {product.inventoryReceived}
              </Card.Section>

              { product.variants.edges.map((variant,j)=>{
                return (
                  <Card.Section title={variant.node.sku} key={j}>
                    <EditProducedQuantity variant={variant}/>
                  </Card.Section>
                )
              })}
            </Card>
          )
        })
        :
        <Spinner></Spinner>
      }

    </Page>
  )
}
const CollectionProductTable = (props) => {
  const {products} = props;
  const types = findUniqProductTypes(products);


  const initiallySortedRows = products.map(product=>{
    return [
      <ShopiLink
        external
        removeUnderline
        url={`https://noyoco.com/admin/products/${product.id.replace('gid://shopify/Product/', '' )}`}
        key="product.handle"
      >
        {product.title}
      </ShopiLink>,
      product.productType,
      product.producedQuantity,
      product.soldQuantity,
      product.inventoryQuantity,
      <ProgressBar progress={product.soldQuantity / product.producedQuantity * 100} />,
      money(product.producedValue),
      money(product.turnover_ht),
      money(product.turnover_ht - product.producedValue)
    ]
  })
  const [sortedRows, setSortedRows] = useState(null);
  const rows = sortedRows ? sortedRows : initiallySortedRows;


  const [queryValue, setQueryValue] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(null);
  const handleSelectedTypeChange = useCallback( (value) => {
    setSelectedTypes(value);

    if (!value.length) {
      setSortedRows(null)
      return
    }
    const newRows = initiallySortedRows.filter(row=>{
      return value.includes(row[1]) ? true : false
    })

    setSortedRows(newRows)
  },[rows]);
  const filters = [
    {
      key: 'productType',
      label: 'Type',
      filter: (
        <ChoiceList
          title="Type"
          choices={types.map( type => {
              return {label: type, value: type}
            }
          )}
          onChange={handleSelectedTypeChange}
          selected={selectedTypes || []}
          allowMultiple
        />
      ),
      shortcut: true,
    }
  ];
  const appliedFilters = [];
  if (!types.length) {
    const key = 'Types';
    appliedFilters.push({
      key,
      label: "disambiguateLabel(key, availability)"
    });
  }
  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    console.log("value", value);
    setSortedRows(initiallySortedRows.filter( row => {
      console.log("row", row);
      var test = row[0].props.children.toLowerCase().includes(value.toLowerCase()) ? true : false;
      console.log("test", test);
      return test
    }))
  },[rows]);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(null);
    setSortedRows(initiallySortedRows)
  }, []);

  const handleSort = useCallback((index, direction) => {
    console.log("handle sort");
    var sorted = rows.sort( (a,b) => {


      // SI C'EST DES EUROS
      if ( typeof a[index] == "string" && a[index].includes('€') ) {
        console.log("c'est des euros!");
        var a2 = parseInt(a[index].replace(' ', ''));
        var b2 = parseInt(b[index].replace(' ', ''));

        // console.log("a", a2);
        // console.log("b", b2, b[index]);

        if (direction === "descending") {
          return a2 < b2 ? -1 : 1
        }
        return b2 < a2 ? -1 : 1
      }

      // SI la valeur est pas là direct
      if (a[index].props) {
        // Si progress bar
        if (a[index].props.progress) {
          if (direction === "descending") {
            return b[index].props.progress - a[index].props.progress
          }else {
            return a[index].props.progress - b[index].props.progress
          }
        }
      }



      if (direction === "descending") {
        var test = a[index] > b[index];
        return a[index] > b[index] ? 1 : -1
      }
      return b[index] > a[index] ? 1 : -1
    })
    setSortedRows(sorted);
  }, [rows]);

  return(
    <Card>
      <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            appliedFilters={appliedFilters}

          />
        </Card.Section>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'numeric',
            'numeric',
            'numeric',
            'text',
            'numeric',
            'numeric',
            'numeric',
          ]}
          headings={[
            'Product',
            'Type',
            'Produced',
            'Sold',
            'Inventory',
            'Sale Through',
            'Budget Prod',
            'Ventes HT',
            'Résultat',
          ]}
          rows={rows}
          onSort={handleSort}
          sortable={[true, true, true, true, true, true, true, true, true]}
          showTotalsInFooter
          totals={[
            0,
            0,
            rows.reduce((t,r)=>(t+=r[2]), 0),
            rows.reduce((t,r)=>(t+=r[3]), 0),
            0,
            <ProgressBar progress={rows.reduce((t,r)=>(t+=r[3]), 0) / rows.reduce((t,r)=>(t+=r[2]), 0) * 100} />,
            money( rows.reduce((t,r)=>(t+=   parseInt( r[6].replace(' ', '')) ), 0)),
            money( rows.reduce((t,r)=>(t+=   parseInt( r[7].replace(' ', '')) ), 0)),
            money( rows.reduce((t,r)=>(t+=   parseInt( r[8].replace(' ', '')) ), 0)),
          ]}
        />
    </Card>
  )
}

const ImagesPage = () => {
  const [products, setProducts] = useState([])
  useEffect( async () => {
    const response = await axios.post("/api/images");
    var {products} = response.data;
    setProducts(products)
  }, []);

  return (
    <Page
        title="Images"
        subtitle="Edit image alt for collection merchandising on the website. Also used for facebook feed"

        >

        <Layout>
          <Layout.Section>
            {products.length?
              <div>
                {products.map( (product, index) =>{
                  return (
                    <Card title={product.title}>
                      {product.images.map( (image, ind)=>{
                        return <EditAlt image={image} key={ind}/>
                      })}
                    </Card>
                  )
                })}
              </div>
              :
              <Spinner></Spinner>
            }
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Mémo">
              <Card.Section title="Instructions">
                <p>Mettre "packshot" sur les packshots</p>
                <p>Mettre "homme" sur les photos hommes</p>
                <p>Mettre "femme" sur les photos femme</p>
              </Card.Section>
              <Card.Section title="Merchandising unisex">
                <p>"collection1" pour définir la premiere photo sur les collections</p>
                <p>"collection2" pour définir la seconde photo sur les collections</p>
              </Card.Section>
              <Card.Section title="Merchandising homme">
                <p>"h1" pour définir la premiere photo sur les collections</p>
                <p>"h2" pour définir la seconde photo sur les collections</p>
              </Card.Section>
              <Card.Section title="Merchandising femme">
                <p>"f1" pour définir la premiere photo sur les collections</p>
                <p>"f2" pour définir la seconde photo sur les collections</p>
              </Card.Section>

            </Card>
          </Layout.Section>
        </Layout>
      }
    </Page>
  )
}
const ReturnPerformancePage = () => {
  console.log('ReturnPerformancePage');
  const [returnReasons, setReturnReasons] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect( async () => {
    const response = await axios.post("/api/performance/returns");
    var {orders, returnedItems, reasons, types} = response.data;
    console.log(orders);
    console.log(returnedItems);
    console.log(reasons);
    console.log(types);
    setReturnReasons(reasons)
    setTypes(types)
  }, []);

  const headings = [
    'Reasons',
    'Total',
    'Male',
    'Female',
    'Details',
  ]
  const columnTypes = [
    'text',
    'numeric',
    'numeric',
    'text',
    'numeric',
    'text',
  ]
  const rows = returnReasons.map( reason => {
    const modalContent = ""
    return [
      reason.title,
      reason.male.quantity + reason.female.quantity,
      reason.male.quantity,
      reason.female.quantity,
      <ModalNew title={`${reason.title}`} content={modalContent}/>,

    ]
  })

  const totalInventorySold = types.reduce((t,type)=>(t+=type.inventorySold), 0);
  const totalReturnedQuandity = types.reduce((t,type)=>(t+=type.returnedQuantity), 0);

  return(
    <Page title="Returns"
      subtitle="Basés sur les ventes en ligne depuis le 20 Avril 2021"
      >
      <Card title="By reasons" sectioned>
        {returnReasons.length?
          <DataTable
            columnContentTypes={columnTypes}
            headings={headings}
            rows={rows}
             />
           :
           <Spinner></Spinner>

         }
      </Card>
      <Card title="By Type" sectioned>
        {types.length?
          <DataTable
            columnContentTypes={[
              "text",
              "numeric",
              "numeric",
              "numeric",
              "text"
            ]}
            headings={[
              "productType",
              "inventorySold",
              "returnedQuantity",
              "returnRate",
              "Details"
            ]}
            rows={types.map(type=>{
              const modalContent = <List type="bullet">
                {Object.keys(type.reasons).map( (key, i) => {
                    return <List.Item key={i}>{key}: {type.reasons[key]}</List.Item>
                })}
              </List>
              return [
                type.title,
                type.inventorySold,
                type.returnedQuantity,
                `${(type.returnRate*100).toFixed(2)}%`,
                <ModalNew title={type.title} content={modalContent}/>
              ]
            })}
            totals={[
              '',
              totalInventorySold,
              totalReturnedQuandity,
              `${(totalReturnedQuandity/totalInventorySold*100).toFixed(2)}%`,
              '-'
            ]}
            showTotalsInFooter
           />
           :
           <Spinner></Spinner>

         }
      </Card>
    </Page>
  )
}
const About = () => {
  return <h2>About</h2>;
}
const Users = () => {
  return <h2>Users</h2>;
}

const EditProducedQuantity = (props) => {
  const {variant} = props
  const {id, sku} = variant.node;
  var la_value = variant.node.metafield ? parseInt(variant.node.metafield.value) : 0

  var initFormState = {
    namespace: 'production',
    key: 'aw21',
    value_type: 'integer',
    value: la_value,
    owner_resource: 'variant',
    owner_id: id.replace("gid://shopify/ProductVariant/", "")
  }
  const [formState, setFormState] = useState(initFormState);
  const [active, setActive] = useState(false);


  const onBlurChanged = async () => {
    console.log("Blur");
    if (initFormState != formState) {
      // console.log("Will update");
      // console.log(formState);
      const response = await axios.post('/api/metafields/create', formState)
      initFormState = formState;
      setActive(true);
      return
    }else{
      console.log("Same form");
    }

  }
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={`SAVED FOR ${sku}`} onDismiss={toggleActive} />
  ) : null;
  return (
    <Card.Section>
      <TextField
        value={`${formState.value}`}
        onChange={ value => {
          setFormState({ ...formState, value: parseInt(value) })
        } }
        onBlur= {onBlurChanged}
        label="Quantité produite"
        inputMode="numeric"
        type="number"
        />
        {toastMarkup}
    </Card.Section>
  )
}
const EditAlt = (props) => {
  const {image} = props
  const {id, product_id, title} = image;
  var {alt} = image;

  const [newAlt, setNewAlt] = useState(alt);
  const [active, setActive] = useState(false);

  const imageStyle = {
    width: '80px'
  };
  const onBlurChanged = async () => {
    // console.log("Blur");
    // console.log("alt", alt);
    // console.log("newAlt", newAlt);
    if (newAlt != alt) {
      console.log("Will update");
      const response = await axios.post('/api/images/update', {
        alt: newAlt,
        product_id,
        id
      })
      alt = newAlt;
      console.log(response.data);
      setActive(true);
      return
    }else{
      console.log("Same alt");
    }

  }
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Saved" onDismiss={toggleActive} />
  ) : null;
  return (
    <Card.Section>
      <Stack>
        <img style={imageStyle} src={image.src} />
        <TextField
          value={newAlt}
          onChange={ value => setNewAlt(value) }
          onBlur= {onBlurChanged}
          label="Balise ALT"
          type="text"
          />
      </Stack>
      {toastMarkup}
    </Card.Section>
  )
}
const ReturnModal = (props) => {
  const [active, setActive] = useState(false);
  const {items, name} = props
  const handleChange = useCallback(() => setActive(!active), [active]);
  const activator = <Button onClick={handleChange}>View {items.length} product</Button>;
  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={name}
        primaryAction={{
          content: 'Close',
          onAction: handleChange,
        }}
        secondaryActions={[
          {
            content: 'View on Shopify',
            onAction: () => {},
          },
        ]}
      >
          {items.map((item, index)=>{
            const echangeMarkup = item.type == "Echange" ? <div>Echange pour : {item.swap_with}</div> : <div></div>
            return(
              <Modal.Section key={index}>

                <Heading>{item.name}</Heading>
                <div>Quantity : {item.quantity}</div>
                <div>Type: {item.type}</div>
                {echangeMarkup}
              </Modal.Section>

            )
          })}
      </Modal>
    </div>
  );
}
const ParcelModal = (props) => {
  const [active, setActive] = useState(false);
  const {parcelNumber, tracking} = props
  const handleChange = useCallback(() => setActive(!active), [active]);
  const activator = <Button onClick={handleChange}>{parcelNumber}</Button>;
  if (tracking) {
    const {timeline} = tracking.shipment;
    return(
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={parcelNumber}
        primaryAction={{
          content: 'Close',
          onAction: handleChange,
        }}
        >
        <Modal.Section>
          Status Colissimo: {timeline[0].shortLabel}
        </Modal.Section>
      </Modal>

    )
  }
  else {
    return(
      <div>{parcelNumber}</div>
    )
  }
}
const ModalNew = (props) => {
  const {title, content} = props
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const activator = <Button onClick={handleChange} icon={DetailedPopUpMajor}></Button>;

  return (
    <Modal
        large
        activator={activator}
        open={active}
        onClose={handleChange}
        title={title}
        primaryAction={{
          content: 'Close',
          onAction: handleChange,
        }}
      >
        <Modal.Section>
          {content}
        </Modal.Section>
      </Modal>
  )
}
const TailleModal = (props) => {
  const {products, type, soldQuantity} = props;
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const activator = <Button onClick={handleChange} icon={DetailedPopUpMajor}>
    {soldQuantity}
  </Button>;

  const [gender, setGender] = useState(null);
  const countVariantSold = (title, gender) => {
    var variants =  products.map(product=>{
      return product.variants.edges.map(edge=>{
        return edge.node
      })
    })
    variants = variants.flat(10);
    // console.log("all variants", variants.length);

    variants = variants.filter(v=>{
      return v.title.toLowerCase() == title.toLowerCase() ? true : false;
    })

    if (gender) {
      return variants.reduce((t,v)=>(t+= v.sales_by_gender[gender].quantity), 0)
    }

    // console.log("variants", title, variants.length);

    return variants.reduce((t,v)=>(t+= v.soldQuantity), 0)
  }
  const countSoldInGender = (gender) => {
    if (gender) {
      var variants =  products.map(product=>{
        return product.variants.edges.map(edge=>{
          return edge.node
        })
      })
      variants = variants.flat(10);
      return variants.reduce((t,v)=>(t+= v.sales_by_gender[gender].quantity), 0)
    }
    return soldQuantity
  }
  const variantSold = (title) => {
    var test =  products.reduce((t,p)=>{
      var c = p.variants.edges.filter(v=>(v.node.title == title ? true : false)).reduce((sub,v)=>{
        return sub+=v.node.soldQuantity
      },0)
      return t += c
    }, 0)
    return test
  }
  const variantProduced = (title) => {
    var test =  products.reduce((t,p)=>{
      var c = p.variants.edges.filter(v=>(v.node.title == title ? true : false)).reduce((sub,v)=>{
        return sub+=v.node.producedQuantity
      },0)
      return t += c
    }, 0)
    return test
  }



  const rows = [
    [
      "XXS",
      countVariantSold('xxs', gender),
      percentage(countVariantSold('xxs', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('xxs',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('XXS')/ variantProduced("XXS") * 100} />,
    ],
    [
      "XS",
      countVariantSold('xs', gender),
      percentage(countVariantSold('xs', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('xs',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('XS')/ variantProduced("XS") * 100} />,
    ],
    [
      "S",
      countVariantSold('s', gender),
      percentage(countVariantSold('s', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('s',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('S')/ variantProduced("S") * 100} />,
    ],
    [
      "M",
      countVariantSold('m', gender),
      percentage(countVariantSold('m', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('m',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('M')/ variantProduced("M") * 100} />,
    ],
    [
      "L",
      countVariantSold('l', gender),
      percentage(countVariantSold('l', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('l',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('L')/ variantProduced("L") * 100} />,
    ],
    [
      "XL",
      countVariantSold('xl', gender),
      percentage(countVariantSold('xl', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('xl',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('XL')/ variantProduced("XL") * 100} />,
    ],
    [
      "XXL",
      countVariantSold('xxl', gender),
      percentage(countVariantSold('xxl', gender)/ countSoldInGender(gender)),
      <ProgressBar progress={countVariantSold('xxl',gender)/ countSoldInGender(gender) * 100} />,
      <ProgressBar progress={variantSold('XXL')/ variantProduced("XXL") * 100} />,
    ],
  ]

  const [queryValue, setQueryValue] = useState(null);
  const handleGenderChange = useCallback((value) => {
    setGender(value);
  },[]);
  const appliedFilters = [];
  const filters = [
    {
      key: 'gender',
      label: 'By gender',
      filter: (
        <ChoiceList
          title="Gender"
          titleHidden
          choices={[
            {label: 'Unknown', value: 'unknown'},
            {label: 'Male', value: 'male'},
            {label: 'Female', value: 'female'}
          ]}
          selected={gender || []}
          onChange={handleGenderChange}
        />
      ),
      shortcut: true,
    }
  ];
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    console.log("Wtf");
  }, []);


  const tagMarkup = gender ? <Tag>{gender}</Tag> : ""

  return (
    <Modal
        large
        activator={activator}
        open={active}
        onClose={handleChange}
        title={type}
        primaryAction={{
          content: 'Close',
          onAction: handleChange,
        }}
      >
        <Modal.Section>
          <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
              hideQueryField
            />
        </Modal.Section>
        <Modal.Section>
          {tagMarkup}
        </Modal.Section>
        <Modal.Section>
          <DataTable
            columnContentTypes={[
              'text',
              'numeric',
              'numeric',
              'text',
              'text',
            ]}
            headings={[
              'Taille',
              'Quantités vendues',
              'En % des ventes',
              'Répartition des ventes',
              'Sale Through',
            ]}
            rows={rows}

          />
        </Modal.Section>
      </Modal>
  )
}
const Signin = () => {

  const [formState, setFormState] = useState({
    email:"",
    password: ""
  })
  const [active, setActive] = useState(false);
  const setInput = useCallback( (key, value) => {
    setFormState({ ...formState, [key]: value })
  }, [formState]);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const handleSubmit = () => {
    const {email, password} = formState;
    if (email == "eric@noyoco.com" && password == "azerty") {
      localStorage.setItem('user', true);
      window.location = "/";
      setActive(false)
    }else{
      setActive(true)
    }
  }
  const toastMarkup = active ? (
    <Toast content="Wrong credentials" error onDismiss={toggleActive} />
  ) : null;


  return(
    <AppProvider i18n={enTranslations}>
      <Frame>
        <Page title="Connexion">
          <Card sectioned>
            <Form onSubmit={handleSubmit}>
              <FormLayout>
                <TextField
                  value={formState.email}
                  onChange={ value => setInput('email', value) }
                  label="Email"
                  type="email"
                  />
                <TextField
                  value={formState.password}
                  onChange={ value => setInput('password', value) }
                  label="Password"
                  type="password"
                  />
                <Button submit>Submit</Button>
              </FormLayout>
            </Form>
            {toastMarkup}
          </Card>
        </Page>
      </Frame>
    </AppProvider>
  )
}



export default App;
